<template>
  <div>
    <!--begin::Tables Widget 9-->
  <div class="card">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1">Kullanıcı grupları</span>
      </h3>

      
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body py-3">
      
      <div class="mb-4">
        <button class="btn btn-primary" @click.prevent="newUserGroup()" data-bs-target="#user_group_modal" data-bs-toggle="modal">Yeni kullanıcı grubu ekle</button>
      </div>

      <!--begin::Table container-->
      <div class="table-responsive">
        <!--begin::Table-->
        <table
          class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-hover"
        >
          <!--begin::Table head-->
          <thead>
            <tr class="fw-bolder text-muted">
              
              <th class="">ID</th>
              <th>Sıra</th>
              <th>Grup adı</th>
              <th>Açıklama</th>
              <th>Kullanıcı ID'leri</th>
              <th>İşlemler</th>
              
             
            </tr>
          </thead>
          <!--end::Table head-->

          <!--begin::Table body-->
          <tbody v-if="list">
            <template v-for="(item, index) in list" :key="index">
              <tr>
                <td>
                  {{item.userGroupId}}
                </td>
                <td>
                  {{item.orderNo}}
                </td>
                <td>
                  {{item.userGroupName}}
                </td>
                <td>
                  {{item.description}}
                </td>
                <td>
                  {{item.userIDs.replaceAll(";","; ")}}
                </td>

                <td>
                  <button data-bs-target="#user_group_modal" @click.prevent="editGroup(item)" data-bs-toggle="modal" class="btn btn-success btn-sm me-2">Düzenle</button>
                  <button class="btn btn-danger btn-sm" @click.prevent="deleteGroup(item)">Sil</button>
                </td>
                
              </tr>
            </template>
          </tbody>
          <!--end::Table body-->
        </table>
       
        <!--end::Table-->
      </div>
      <!--end::Table container-->
    </div>
    <!--begin::Body-->
  </div>
  <!--end::Tables Widget 9-->

  <div class="modal fade" id="user_group_modal" tabindex="-1" aria-modal="true" role="dialog">
			<!--begin::Modal dialog-->
			<div class="modal-dialog modal-dialog-centered mw-600px">
				<!--begin::Modal content-->
				<div class="modal-content">
					<!--begin::Modal header-->
					<div class="modal-header">
						<!--begin::Modal title-->
						<h2>Kullanıcı grubu detayı</h2>
						<!--end::Modal title-->
						<!--begin::Close-->
						<div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
							<!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
							<span class="svg-icon svg-icon-1">
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
									<rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect>
									<rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect>
								</svg>
							</span>
							<!--end::Svg Icon-->
						</div>
						<!--end::Close-->
					</div>
					<!--end::Modal header-->
					<!--begin::Modal body-->
          <div class="modal-body py-lg-10 px-lg-10">
            <div class="row mb-4">
              <div class="col-lg-4 form-label">
                Kullanıcı grubu adı
              </div>

              <div class="col-lg-8">
                <input type="text" name="UserGroupName" v-model="UserGroupName" class="form-control">
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-lg-4 form-label">
                Açıklama
              </div>

              <div class="col-lg-8">
                <input type="text" name="description" v-model="description" class="form-control">
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-lg-4 form-label">
                Sıra
              </div>

              <div class="col-lg-8">
                <input type="text" name="orderNo" v-model="orderNo" class="form-control">
              </div>
            </div>
           
            <div class="row mb-4">
              <div class="col-lg-4 form-label">
                Kullanıcı ID'leri
              </div>

              <div class="col-lg-8">
                <textarea name="userIDs" v-model="userIDs" class="form-control" id="userIDs" cols="30" rows="10"></textarea>
                
              </div>
            </div>

           
          </div>

          <div class="modal-footer">
            <button class="btn btn-light" data-bs-target="#user_group_modal" data-bs-toggle="modal">Kapat</button>
            <button class="btn btn-success" @click="sendUserGroup()" >Kaydet</button>
          </div>
					
					<!--end::Modal body-->
				</div>
				<!--end::Modal content-->
			</div>
			<!--end::Modal dialog-->
		</div>



  </div>
</template>

<script>
import api from "@/core/services/ApiService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import CountryFlag from 'vue-country-flag-next'
import Filter from '@/components/filters/ClientListFilter.vue'
import Paging from '@/components/BD/SelectPaging.vue'
import Swal from "sweetalert2/dist/sweetalert2.js";



export default {
  name: "Clients",
  components:{
    Paging,
    CountryFlag,
    Filter
  },
  data(){
    return {

      postApi: '/User/NewUserGroup',
      successMessage:'Kullanıcı grubu başarıyla eklendi',
      
      UserGroupName:null,
      description:null,
      userIDs:null,
      userGroupId:null,
      orderNo:1,
      
      list:null,
      
    }
  },
  
  methods:{

    removeDuplicates(arr) {
      return arr.filter((item,index) => arr.indexOf(item) === index);
    },

    deleteGroup(item){
      Swal.fire({
        icon:'warning',
        title:'Emin misiniz?',
        text:'Kullanıcı grubunu silmek istediğinize emin misiniz?',
        showCancelButton:true
      }).then((res)=>{
        if(res.isConfirmed){
          api.get('/User/DeleteUserGroup/'+item.userGroupId).then((res)=>{
            Swal.fire({
              text:'Kullanıcı grubu silindi',
              icon:'success',
              title:'Başarılı'
            }).then((res)=>{
              location.reload();
            })
          }).catch((err)=>{
            Swal.fire({
              icon:'error',
              title:'Hata',
              text:'Hatalı işlem'
            });
          })
        }
      })
    },

    sendUserGroup(){
      
      var idList = this.userIDs.split(";");
      idList = this.removeDuplicates(idList);
      
      let data = {
        UserGroupName:this.UserGroupName,
        description:this.description,
        userIDs:idList.join(";"),
        orderNo:this.orderNo * 1,
        Status:1
        
      }

      if(this.userGroupId){
        data.userGroupId = this.userGroupId;
      }
     
      api.post(this.postApi,data).then((res)=>{
       Swal.fire({
        title:'Başarılı',
        text:this.successMessage,
        icon:'success',
       }).then((res)=>{
        location.reload();
       })
      });
    },

    newUserGroup(){
      this.UserGroupName =null;
      this.description =null;
      this.userIDs =null;
      this.orderNo =1;
      this.Status =1;
      this.successMessage = 'Kullanıcı grubu başarıyla eklendi';
      this.userGroupId =null;
      this.postApi = '/User/NewUserGroup';

      
      
      
    },

    editGroup(item){
      this.postApi = '/User/UpdateUserGroup';
      this.group = item;
      this.UserGroupName =item.userGroupName;
      this.description =item.description;
      this.userIDs =item.userIDs;
      this.orderNo =item.orderNo;
      this.userGroupId =item.userGroupId; 
      this.successMessage = 'Kullanıcı grubu güncellendi';   
    },



  

    
    
     getClientsList(){
      
      let apiUrl = '/User/ListUserGroup';

      api.get(apiUrl).then((res)=>{
         this.list=res.data;
         
      })
    },

    
  },
  mounted(){
   
    this.getClientsList();
    setCurrentPageBreadcrumbs("Kullanıcı grupları listesi", ["Kullanıcı grupları"]);
  }
  
  }
</script>
